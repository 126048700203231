.animate-spin {
    animation: spin 10s linear infinite;
}

header {
    position: sticky;
    top: 0;
    z-index: 999;


}

.card:hover {
    opacity: 1;
    transform: translateY(-10px);
}

.card {
    transition: opacity 0.5s ease-in-out;
    transition: transform 0.2s;

}

/* Add this to your CSS file */
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.card-animation-left {
    animation: slideInFromLeft 0.5s ease-out;
}

.card-animation-right {
    animation: slideInFromRight 0.5s ease-out;
}